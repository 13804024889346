export default {
  Message: {
    Confirm: '确认',
    Cancel: '关闭',
    Title: '信息',
    msg401: '权限不足！',
    msg400: '系统异常！',
    msg404: '请求接口不存在！',
    msg500: '服务器错误！',
    msg502: '网络错误！',
    msg503: '服务不可用，服务器暂时过载或维护。'
  },
  DeliveryAddress: {
    Address: '地址'
  },
  Order: {
    OrderNumber: '订单编号',
    OrderDateTime: '订单日期&时间 ',
    TotalPrice: '总价',
    OrderStatus: '订单状态',
    Paid: '已付款',
    Unpaid: '待付款',
    PayNow: '立即支付'
  },
  RegNPay: {
    BackText: '返回',
    UploadButtonText: '上传文件(jpg, png, pdf)',
    UploadingText: '正在上传',
    ConfirmText: '确定',
    UploadSuccessfulText: '上传成功',
    UploadFailText: '上传失败',
    NoFileText: '没有选中文件',
    UploadLengthText: '最多只能上载{0}文件',
    UploadSizeText: '最多只能上传{0}M',
    PleaseSelect: '请选择',
    SubRecord: '提交纪录',
    ReferenceNum: '参考编号',
    SubmissionDate: '提交日期',
    Print: '列印',
    PlsPayWay: '请选择付款方式',
    PreviewTitleText: '请再次确认页面',
    FormatErrorText: '请输入正确电邮地址',
    RequiredText: '请检查你的答案'
  },
  Display: {
    SuccessCase: '成功个案',
    GeneralQuery: '一般查询',
    OfficeHours: '办公时间',
    Hour0: '星期一至五：',
    Hour1: '早上9时至下午12时,',
    Hour2: '下午1时30分至晚上6时',
    Hour3: '星期六、日及公众期假休息',
    AddrInfo: '长沙湾荔枝角道 777号田氏企业中心 3楼 306室 (长沙湾港铁站 B出口)',
    Last: '上一页',
    Next: '下一页',
    Total: '共',
    Page: '页',
    Per: '第',
    AskQuote: '询价',
    BackToYou: '我们会尽快回复你！',
    WeProvide: '我们提供',
    Professional: '专业',
    And: '及',
    Reliable: '可靠',
    Services: '服务',
    SerCategory: '会计服务 | 法定审计 | 税务服务 | 公司秘书服务 | 香港/海外公司成立',
    AboutUs: '关于我们',
    OurServices: '服务种类',
    OurOffice: '我们的办公室',
    SearchResult: '搜索结果',
    Date: '日期',
    NothingMatch: '没有符合你的搜索条件。请用不同的关键字再试一次。'
  },
  Action: {
    ViewMore: '查看更多',
    LearnMore: '查看更多',
    ReadMore: '查看更多',
    SignUp: '立即报名',
    ReturnHomePage: '返回首页',
    OrderDetails: '订单详情'
  },
  Cms: {
    PlsChoose: '请点击选择您想要查看的内容：'
  },
  payment: {
    PaymentSuccessTips: '支付结果： 成功',
    PaymentFailTips: '支付结果： 失败'
  },
  Calendar: {
    KnowMore: '了解更多',
    Time: '时间',
    Venue: '地点',
    NoEvent: '今日暂无活动'
  },
  photo: {
    photo: '相片',
    BackText: '返回上一级'
  }
};
